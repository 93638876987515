'use strict';

/**
 *
 * @param {string} name
 * @param {string} value
 * @param {Date} expires
 * @param {string} path
 * @param {string} domain
 * @param {boolean} secure
 */
const setCookie = (name, value, expires, path, domain, secure) => {
  var cookieVal = name + '=' + encodeURI(value) + '; ';
  if (expires) {
    cookieVal += 'expires=' + expires.toUTCString() + '; ';
  }
  if (path) {
    cookieVal += 'path=' + path + '; ';
  }
  if (domain) {
    cookieVal += 'domain=' + domain + '; ';
  }

  cookieVal += 'SameSite=Strict;';

  if (secure) {
    cookieVal += 'secure' + '; ';
  }
  document.cookie = cookieVal;
};

/**
 *
 * @param {string} name
 * @returns boolean
 */
const hasCookie = (name) => {
  return document.cookie.indexOf(name) > -1;
};

exports.setCookie = setCookie;
exports.hasCookie = hasCookie;
