/* globals gtag, fbq, $, cookies, gtag_ads_report_conversion */
'use strict';

const { setCookie } = require('../../lib/cookie');

function closeModal() {
  $('#fee-info-overlay').fadeOut(300);
  $('#guarantee-info-overlay').fadeOut(300);
  $('.sustainability-info-overlay').fadeOut(300);
  if ($('.menu-button-desktop').hasClass('close')) {
    closeDesktopMenu();
  }
}

function openModal(id) {
  $(id).fadeIn(200);
}

//Menu
//var targetElement;
function openDesktopMenu() {
  var overlay = $('#desktop-menu-overlay');
  openModal(overlay);
  $('.menu-button-desktop').addClass('close');
  $('.lines').addClass('active');
  $('.header').addClass('menuActive');
}

function closeDesktopMenu() {
  var overlay = $('#desktop-menu-overlay');
  overlay.fadeOut(300);
  $('.menu-button-desktop').removeClass('close');
  $('.lines').removeClass('active');
  $('.header').removeClass('menuActive');
}

var debugMode = false;

$(function () {
  // Menu mobile
  var scrollPosition = $(window).scrollTop();
  $('.menu-button-mobile').click(function () {
    if (!$(this).hasClass('close')) {
      //Open menu
      scrollPosition = $('body').scrollTop();
      $(this).addClass('close');
      $('body').addClass('navActive');
      $('.lines').addClass('active');
      $('.mobile-header').addClass('active');
      $('.mobile-menu').addClass('active');
    } else {
      //Close menu
      $(this).removeClass('close');
      $('body').removeClass('navActive');
      $('.lines').removeClass('active');
      $('.mobile-header').removeClass('active');
      $('.mobile-menu').removeClass('active');
      $('body').scrollTop(scrollPosition);
      $('#mobile-menu-container').scrollTop(0);
    }
  });

  //Menu desktop

  $('#desktop-menu-overlay').click(function () {
    closeDesktopMenu();
  });

  $('.menu-button-desktop').click(function () {
    if ($(this).hasClass('close')) {
      closeDesktopMenu();
    } else {
      openDesktopMenu();
    }
  });

  //Info
  $('#fee-info-tag').click(function () {
    openModal('#fee-info-overlay');
  });

  //Guarantee
  $('#guarantee-info-tag').click(function () {
    openModal('#guarantee-info-overlay');
  });

  //Marketing download
  $('#marketing-download-download-button').click(function () {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      gtag_ads_report_conversion(
        'https://play.google.com/store/apps/details?id=se.opti.android',
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      gtag_ads_report_conversion('https://itunes.apple.com/app/id1179422060');
    } else {
      window.scrollTo(0, 0);
      $('.overlay-container').fadeIn(0);
    }
  });

  $('#download-button-nav-m').click(function () {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      window.open(
        'https://play.google.com/store/apps/details?id=se.opti.android',
        '_blank',
      ); //Android
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open('https://itunes.apple.com/app/id1179422060', '_blank'); //iOS
    } else {
      window.scrollTo(0, 0);
      $('.overlay-container').fadeIn(0);
    }
  });
  $('.overlay').click(function () {
    closeModal();
  });
  $('#download-close-btn').click(function () {
    closeModal();
  });
  $(document).keyup(function (event) {
    if (event.keyCode === 27) {
      closeModal();
    }
  });

  $('.overlay').click(function () {
    closeModal();
  });

  $('.close-btn').click(function () {
    closeModal();
  });

  $(document).keyup(function (event) {
    if (event.keyCode === 27) {
      closeModal();
    }
  });

  // Cookies overlay / Star

  const googleToggleButton = $('#cookies-toggle-button-google');
  googleToggleButton.click(function () {
    googleToggleButton.toggleClass('is-on');
  });

  const facebookToggleButton = $('#cookies-toggle-button-facebook');
  facebookToggleButton.click(function () {
    facebookToggleButton.toggleClass('is-on');
  });

  const showCookieOverlay = () => {
    setTimeout(() => {
      $('#cookies-overlay').addClass('show');
    }, 500);
  };

  const hideCookieOverlay = () => {
    $('#cookies-overlay').removeClass('show');
    $('.cookies-overlay-state-manage.background').removeClass('show');
  };

  const showManageCookiesSetting = () => {
    $('.cookies-overlay-state-manage').addClass('show');
    $('.cookies-overlay-state-default').addClass('hide');
    $('#cookies-overlay > .scroll-container').addClass('expanded');
  };

  const showDefaultCookiesSetting = () => {
    $('.cookies-overlay-state-manage').removeClass('show');
    $('.cookies-overlay-state-default').removeClass('hide');
    $('#cookies-overlay > .scroll-container').removeClass('expanded');
  };

  const initializeTracking = () => {
    // 'initializeGoogleAnalytics()' and 'initializeFacebookPixel()' is defined in 'base.hbs'
    initializeGoogleAnalytics(); // eslint-disable-line no-undef
    initializeFacebookPixel(); // eslint-disable-line no-undef
    location.reload();
  };

  const setTackingCookie = (enableFacebookTracking, enableGoogleTracking) => {
    let expiryDate = new Date();

    // 'cookieDomain' is defined in 'base.hbs'
    let domain = cookieDomain; // eslint-disable-line no-undef

    expiryDate.setDate(expiryDate.getDate() + 5 * 365);
    setCookie(cookies.essential, 'true', expiryDate, '/', domain);

    if (enableFacebookTracking) {
      setCookie(cookies.facebook, 'true', expiryDate, '/', domain);
    }
    if (enableGoogleTracking) {
      setCookie(cookies.google, 'true', expiryDate, '/', domain);
    }

    hideCookieOverlay();
    initializeTracking();
  };

  const deleteAllCookies = () => {
    document.cookie.split(';').forEach((cookie) => {
      let name = cookie;
      const equalPosition = cookie.indexOf('=');
      if (equalPosition) {
        name = cookie.substring(0, equalPosition);
      }
      document.cookie = `${name} =; Path=/; Domain=${document.domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  };

  $('#cookies-button-manage').click(function () {
    showManageCookiesSetting();
  });

  $('#cookies-button-accept').click(function () {
    setTackingCookie(true, true);
  });

  $('#cookies-button-save').click(function () {
    setTackingCookie(
      facebookToggleButton.hasClass('is-on'),
      googleToggleButton.hasClass('is-on'),
    );
  });

  $('#cookies-button-cancel').click(function () {
    showDefaultCookiesSetting();
  });

  $(document).ready(function () {
    if (!cookies.hasAnsweredCookieQuestion()) {
      showCookieOverlay();
      deleteAllCookies();
    }
  });

  // Cookies overlay / End
  /* eslint-disable camelcase */
  function trackDownload() {
    if (!debugMode) {
      fbq('track', 'DownloadClicked');

      var pid = location.search.split('pid=')[1];
      if (pid === 'rika-tillsammans') {
        gtag('event', 'download_clicked', {
          event_category: 'reading',
          event_label: 'Rika Tillsammans',
        });
      } else {
        gtag('event', 'download_clicked', {
          event_category: 'reading',
          event_label: document.title,
        });
      }
    } else {
      console.log('Download clicked');
    }
  }

  $('.ios-download, .android-download').click(function () {
    trackDownload();
  });
  /* eslint-enable camelcase  */

  // Header
  // When header has '.transparent' it should be transparent in the top
  // and default when scrolled down
  const defaultClassName = 'transparent';

  const scrolled = (element, threshold) => {
    if (window.scrollY > threshold && element.hasClass(defaultClassName)) {
      element.removeClass(defaultClassName);
    } else if (
      window.scrollY <= threshold &&
      !element.hasClass(defaultClassName)
    ) {
      element.addClass(defaultClassName);
    }
  };

  const configure = (element, threshold) => {
    if (element.hasClass(defaultClassName)) {
      const headerScroller = () => scrolled(element, threshold);
      $(window).scroll(headerScroller);
      headerScroller();
    }
  };

  configure($('header.header'), 100);
  configure($('header.mobile-header'), 470);
});

$(function () {
  var faqTrigger = $('.faq-trigger');
  var activeSection = null;
  var isMobile = $('#nav-bar').css('display') === 'none';

  $(window).keydown(function (e) {
    // expand all faq items on ctl+f or cmd+f
    if ((e.keyCode === 70 && e.ctrlKey) || (e.keyCode === 70 && e.metaKey)) {
      faqTrigger.each(function () {
        openSection($(this));
      });
    }
    // close all faq items on esc
    else if (e.keyCode === 27) {
      faqTrigger.each(function () {
        closeSection($(this));
      });
    }
  });

  setTimeout(function () {
    updateForCurrentHash(true);
  }, 200);

  //show faq content clicking on faqTrigger
  faqTrigger.on('click', function (event) {
    event.preventDefault();
    toggle($(this).attr('id'));
  });

  function hashHandler() {
    updateForCurrentHash(false);
  }
  window.addEventListener('hashchange', hashHandler, false);

  faqTrigger.each(function () {
    $(this).attr('href', window.location.pathname + '#' + $(this).attr('id'));
  });

  function toggle(id) {
    var hash = window.location.hash.substring(1);
    if (hash === id) {
      window.history.pushState('', '', window.location.pathname);
      updateForId(false, '');
    } else {
      window.history.pushState('', '', window.location.pathname + '#' + id);
      updateForId(false, id);
    }
  }

  function updateForCurrentHash(scrollToItem) {
    updateForId(scrollToItem, window.location.hash.substring(1));
  }

  function updateForId(scrollToItem, id) {
    if (id !== '') {
      activeSection = $('#' + id);

      if (activeSection.parent('li').hasClass('content-visible')) {
        closeSection(activeSection);
        window.history.pushState('', '', window.location.pathname);
      } else {
        openSection(activeSection);
      }

      // Navigation bar is smaller on mobile
      let offset = isMobile ? 50 : 100;

      // If `activeSection` is of type page anchor offset is added in css
      if (activeSection.hasClass('page-anchor')) {
        offset = 0;
      }

      $('html, body').animate(
        {
          scrollTop: activeSection.offset().top - offset,
        },
        300,
      );
    } else if (activeSection !== null) {
      closeSection(activeSection);
      activeSection = null;
    }
  }
});

function openSection(section) {
  let parent = section.parent('li');
  if (parent.hasClass('content-visible')) {
    return;
  }
  parent.toggleClass('content-visible');
  section.next('.faq-content').slideToggle(300).end();
  parent.find('.icon-close').addClass('rotate');
}

function closeSection(section) {
  let parent = section.parent('li');
  if (!parent.hasClass('content-visible')) {
    return;
  }
  parent.toggleClass('content-visible');
  section.next('.faq-content').slideToggle(200).end();
  parent.find('.icon-close').removeClass('rotate');
}

// Tracking

$(function () {
  $('a[event-name]').each((_, element) => {
    const event = $(element).attr('event-name');
    const parametersString = $(element).attr('event-parameters');
    const parameters = {};
    parametersString.split(',').forEach((parameterString) => {
      const split = parameterString.split(':');
      parameters[split[0]] = split[1];
    });

    $(element).click(() => {
      gtag('event', event, parameters);
    });
  });
});
